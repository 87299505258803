import React, { useState,useEffect } from 'react';
import { Link as RouterLink ,useHistory} from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
//----------------------------------------material------------
import { IconButton,Container, Box, Grid, makeStyles, Typography, Paper, TextField, Button,CircularProgress,styled } from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

//------------------------------------------------------------------------------------
axios.defaults.withCredentials = true;

const LoadBox=styled(Box)({
     display:'flex',
            alignItems:'center',
            width:'100%',
            height:'100vh',
            backgroundColor:'inherit',
            justifyContent:'center',
})

const useStyles = makeStyles((theme) => ({
    Root: {
        backgroundImage: ' linear-gradient(131.27deg, rgba(6, 14, 81, 0.92) 82.91%, rgba(25, 2, 43, 0.7268) 109.34%, rgba(81, 34, 118, 0.7176) 123.58%);',
        minHeight: '100vh',

        padding: 0,
        '&::before': {
            content: '""',
            position: 'absolute',
            height: 320,
            width: 350,
            backgroundColor: 'transparent',
            bottom: 0,
            right: 0,
            backgroundImage: 'url(logo-watermark2.svg)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',

        },
        SignInBtn: {

        },
        LoadScreen:{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            width:'100%',
            height:'100vh',
            backgroundColor:'yellow'
        }

    }
}))
const url = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV : process.env.REACT_APP_API;

export default function Main() {
    const classes = useStyles();
    const navigate = useHistory()
    //box-shadow: 0px 4px 4px 0px #00000040;
    const [showPassword, setshowPassword] = useState(false)
    const [notify, setNotify] = useState('');
    const [loading,setLoading]= useState(true)
    const [isLoggedin,setLoggedin]= useState(false)
    useEffect(()=>{
        axios.post(`${url}/api/user/verify`,{})
        .then(res=>{
            if(res.data===200){
                  //  window.location.href =`${res.data.redirectURL}`
          setLoading(false)
          setLoggedin(true)
            }
             setLoading(false)
            //console.log('success ✔✔✔',res.data,localStorage.getItem('AuthToken'))
            //perform some storage actions

            //redirect to sub-domain
      
        }).catch(err=>{
            setLoading(false)

        })
    },[])


    const FormSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required')
    });
    const onSubmit = async (vals) => {
     
        const payload = {
            email: vals.email,
            password: vals.password
        }
        try {
            const post = await axios.post(`${url}/api/user/login`, payload)
            if (post) {
                //redirect to subdomain linked to this account
               // console.log(post.data)
                  axios.defaults.headers.common["Authorization"] = post.data.token;
                //localStorage.setItem('AuthToken',post.data.token)
                window.location.href = `${post.data.redirectURL}`
            }else{
                setNotify('Login failed!')

            }
        } catch (error) {
            //400 404
         
            if(error?.response){
                 setNotify(error.response.data || 'Operation failed !')

            }else{
                setNotify('Operation failed !')

            }
        }
    }
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: true
        },
        validationSchema: FormSchema,
        onSubmit
    });
    const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;
const handleClickShowPassword = () => {
    setshowPassword( !showPassword );
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
    return (
        <Container maxWidth='xl' className={classes.Root}>
            <Box style={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: '#060E51', padding: '.8rem 2rem' }} >
                <Typography variant='body1' component={RouterLink} to='/' style={{ color: '#fff', fontWeight: 550, textDecoration: 'none', fontFamily: 'lato' }}>Sign in</Typography>
            </Box>
           {!loading&&isLoggedin===false&& <Box sx={{ width: '100%', height: 'calc(100vh - 50px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                           <Paper style={{ height: 450, width: 380, padding: '1rem', borderRadius: 10, zIndex: 3 }}>
           
                               <Grid container direction='column' justifyContent='center' alignItems='center'>
                                   <Grid item xs={12}>
                                       <Box sx={{
                                           backgroundColor: '#fff', width: 80, height: 80, borderRadius: 999, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 3px 0px #00000040', marginTop: -60
                                       }}>
                                           <img src={`/transparent-logo.png`} alt='BOST' height='50px' width='55px' />
                                       </Box>
           
                                   </Grid>
                                   <Grid item xs={12}>
                                       <Typography style={{ color: '#424242', fontSize: '1.3rem', fontWeight: 600, paddingTop: '1rem', lineHeight: '40px', fontFamily: 'lato' }}>Log in to your account</Typography>
           
                                   </Grid>
                                   <Grid item xs={12}>
           
                                       {notify !== '' ? <Alert severity='error'>{notify} </Alert>
                                           : null}
                                   </Grid>
                                   <Grid container item xs={10}>
                                       <FormikProvider value={formik}>
                                           <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
           
                                               <Grid container spacing={2} style={{ marginTop: '2.1rem' }}>
                                                   <Grid item xs={12}>
                                                       <TextField type='email' name='email' label='Email' variant='outlined' fullWidth onChange={handleChange}
                                                           error={Boolean(touched.email && errors.email)}
                                                           helperText={touched.email && errors.email} />
                                                   </Grid>
                                                   <Grid item xs={12}>
                                                       <TextField type={showPassword ? "text" : "password"} name='password' label='Password' variant='outlined' fullWidth onChange={handleChange}
                                                           error={Boolean(touched.password && errors.password)}
                                                           helperText={touched.password && errors.password}
                                                            InputProps={{
                                                                    endAdornment:(
                                                                  <InputAdornment position="end">
                                                                    <IconButton
                                                                      aria-label="toggle password visibility"
                                                                      onClick={handleClickShowPassword}
                                                                      onMouseDown={handleMouseDownPassword}
                                                                      edge="end"
                                                                    >
                                                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                  </InputAdornment>
                                                                    )
                                                             }}
                                                       />
                                                   </Grid>
                                                   <Grid item xs={12}>
                                                       <Button
                                                           fullWidth
                                                           size="large"
                                                           type="submit"
                                                           variant="contained"
                                                           className={classes.SignInBtn}
                                                           style={{ height: 55, color: '#fff', backgroundImage: 'linear-gradient(45deg, #1c225f 30%,#233297 90%)', textTransform: 'none' }}
                                                       >
                                                           <Typography style={{ fontFamily: 'lato', fontWeight: 600, }}>Sign in
                                                           </Typography>
                                                       </Button>
           
                                                   </Grid>
           
                                               </Grid>
                                           </Form>
                                       </FormikProvider>
           
                                   </Grid>
           
                               </Grid>
           
                           </Paper>
           
                       </Box>}
         <LoadBox>
             { loading&& <CircularProgress/>}
         </LoadBox> 
        </Container>
    )
}
