import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";


import Axios from "axios";
import { createTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import Main from './main'
//------------------------------------------------------------------------

Axios.defaults.withCredentials = true;
export default function App() {
  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
   
    return () => {
    
    };
  }, []);
  const theme = createTheme({
    
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>         
        
            <Main/>
         
      </Router>
    </ThemeProvider>
  );
}
